@charset "UTF-8";

/*
* Symbolset
* www.symbolset.com
* Copyright © 2012 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-pika.css" rel="stylesheet" />
*/

@font-face {
    font-family: "SSGizmo";
    font-weight: normal;
    font-style: normal;
}

.ss-icon,
.ss-icon.ss-gizmo,
[class^="ss-"]:before,
[class*=" ss-"]:before,
[class^="ss-"].ss-gizmo:before,
[class*=" ss-"].ss-gizmo:before,
[class^="ss-"].right:after,
[class*=" ss-"].right:after,
[class^="ss-"].ss-gizmo.right:after,
[class*=" ss-"].ss-gizmo.right:after {
    font-family: "SSGizmo";
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    white-space: nowrap;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga";
    font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    vertical-align: initial;
}

[class^="ss-"].right:before,
[class*=" ss-"].right:before,
[class*=" ss-"].ss-gizmo.right:before {
    display: none;
    content: '';
}

.ss-cursor:before,
.ss-cursor.right:after {
    content: ''
}

.ss-crosshair:before,
.ss-crosshair.right:after {
    content: '⌖'
}

.ss-search:before,
.ss-search.right:after {
    content: '🔎'
}

.ss-zoomin:before,
.ss-zoomin.right:after {
    content: ''
}

.ss-zoomout:before,
.ss-zoomout.right:after {
    content: ''
}

.ss-view:before,
.ss-view.right:after {
    content: '👀'
}

.ss-attach:before,
.ss-attach.right:after {
    content: '📎'
}

.ss-link:before,
.ss-link.right:after {
    content: '🔗'
}

.ss-unlink:before,
.ss-unlink.right:after {
    content: ''
}

.ss-move:before,
.ss-move.right:after {
    content: ''
}

.ss-write:before,
.ss-write.right:after {
    content: '✎'
}

.ss-writingdisabled:before,
.ss-writingdisabled.right:after {
    content: ''
}

.ss-erase:before,
.ss-erase.right:after {
    content: '✐'
}

.ss-compose:before,
.ss-compose.right:after {
    content: '📝'
}

.ss-lock:before,
.ss-lock.right:after {
    content: '🔒'
}

.ss-unlock:before,
.ss-unlock.right:after {
    content: '🔓'
}

.ss-key:before,
.ss-key.right:after {
    content: '🔑'
}

.ss-backspace:before,
.ss-backspace.right:after {
    content: '⌫'
}

.ss-ban:before,
.ss-ban.right:after {
    content: '🚫'
}

.ss-smoking:before,
.ss-smoking.right:after {
    content: '🚬'
}

.ss-nosmoking:before,
.ss-nosmoking.right:after {
    content: '🚭'
}

.ss-trash:before,
.ss-trash.right:after {
    content: ''
}

.ss-target:before,
.ss-target.right:after {
    content: '◎'
}

.ss-tag:before,
.ss-tag.right:after {
    content: ''
}

.ss-bookmark:before,
.ss-bookmark.right:after {
    content: '🔖'
}

.ss-flag:before,
.ss-flag.right:after {
    content: '⚑'
}

.ss-like:before,
.ss-like.right:after {
    content: '👍'
}

.ss-dislike:before,
.ss-dislike.right:after {
    content: '👎'
}

.ss-heart:before,
.ss-heart.right:after {
    content: '♥'
}

.ss-star:before,
.ss-star.right:after {
    content: '⋆'
}

.ss-sample:before,
.ss-sample.right:after {
    content: ''
}

.ss-crop:before,
.ss-crop.right:after {
    content: ''
}

.ss-layers:before,
.ss-layers.right:after {
    content: ''
}

.ss-layergroup:before,
.ss-layergroup.right:after {
    content: ''
}

.ss-pen:before,
.ss-pen.right:after {
    content: '✒'
}

.ss-bezier:before,
.ss-bezier.right:after {
    content: ''
}

.ss-pixels:before,
.ss-pixels.right:after {
    content: ''
}

.ss-phone:before,
.ss-phone.right:after {
    content: '📞'
}

.ss-phonedisabled:before,
.ss-phonedisabled.right:after {
    content: ''
}

.ss-touchtonephone:before,
.ss-touchtonephone.right:after {
    content: '☏'
}

.ss-mail:before,
.ss-mail.right:after {
    content: '✉'
}

.ss-inbox:before,
.ss-inbox.right:after {
    content: '📥'
}

.ss-outbox:before,
.ss-outbox.right:after {
    content: '📤'
}

.ss-chat:before,
.ss-chat.right:after {
    content: '💬'
}

.ss-user:before,
.ss-user.right:after {
    content: '👤'
}

.ss-users:before,
.ss-users.right:after {
    content: '👥'
}

.ss-usergroup:before,
.ss-usergroup.right:after {
    content: ''
}

.ss-businessuser:before,
.ss-businessuser.right:after {
    content: ''
}

.ss-man:before,
.ss-man.right:after {
    content: '👨'
}

.ss-male:before,
.ss-male.right:after {
    content: ''
}

.ss-woman:before,
.ss-woman.right:after {
    content: '👩'
}

.ss-female:before,
.ss-female.right:after {
    content: ''
}

.ss-raisedhand:before,
.ss-raisedhand.right:after {
    content: '✋'
}

.ss-hand:before,
.ss-hand.right:after {
    content: ''
}

.ss-pointup:before,
.ss-pointup.right:after {
    content: '👆'
}

.ss-pointupright:before,
.ss-pointupright.right:after {
    content: ''
}

.ss-pointright:before,
.ss-pointright.right:after {
    content: '👉'
}

.ss-pointdownright:before,
.ss-pointdownright.right:after {
    content: ''
}

.ss-pointdown:before,
.ss-pointdown.right:after {
    content: '👇'
}

.ss-pointdownleft:before,
.ss-pointdownleft.right:after {
    content: ''
}

.ss-pointleft:before,
.ss-pointleft.right:after {
    content: '👈'
}

.ss-pointupleft:before,
.ss-pointupleft.right:after {
    content: ''
}

.ss-cart:before,
.ss-cart.right:after {
    content: ''
}

.ss-creditcard:before,
.ss-creditcard.right:after {
    content: '💳'
}

.ss-calculator:before,
.ss-calculator.right:after {
    content: ''
}

.ss-barchart:before,
.ss-barchart.right:after {
    content: '📊'
}

.ss-piechart:before,
.ss-piechart.right:after {
    content: ''
}

.ss-box:before,
.ss-box.right:after {
    content: '📦'
}

.ss-home:before,
.ss-home.right:after {
    content: '⌂'
}

.ss-globe:before,
.ss-globe.right:after {
    content: '🌎'
}

.ss-navigate:before,
.ss-navigate.right:after {
    content: ''
}

.ss-compass:before,
.ss-compass.right:after {
    content: ''
}

.ss-signpost:before,
.ss-signpost.right:after {
    content: ''
}

.ss-location:before,
.ss-location.right:after {
    content: ''
}

.ss-floppydisk:before,
.ss-floppydisk.right:after {
    content: '💾'
}

.ss-database:before,
.ss-database.right:after {
    content: ''
}

.ss-hdd:before,
.ss-hdd.right:after {
    content: ''
}

.ss-microchip:before,
.ss-microchip.right:after {
    content: ''
}

.ss-music:before,
.ss-music.right:after {
    content: '♫'
}

.ss-headphones:before,
.ss-headphones.right:after {
    content: '🎧'
}

.ss-discdrive:before,
.ss-discdrive.right:after {
    content: ''
}

.ss-volume:before,
.ss-volume.right:after {
    content: '🔈'
}

.ss-lowvolume:before,
.ss-lowvolume.right:after {
    content: '🔉'
}

.ss-mediumvolume:before,
.ss-mediumvolume.right:after {
    content: ''
}

.ss-highvolume:before,
.ss-highvolume.right:after {
    content: '🔊'
}

.ss-airplay:before,
.ss-airplay.right:after {
    content: ''
}

.ss-camera:before,
.ss-camera.right:after {
    content: '📷'
}

.ss-picture:before,
.ss-picture.right:after {
    content: '🌄'
}

.ss-video:before,
.ss-video.right:after {
    content: '📹'
}

.ss-webcam:before,
.ss-webcam.right:after {
    content: ''
}

.ss-film:before,
.ss-film.right:after {
    content: ''
}

.ss-playvideo:before,
.ss-playvideo.right:after {
    content: ''
}

.ss-videogame:before,
.ss-videogame.right:after {
    content: '🎮'
}

.ss-play:before,
.ss-play.right:after {
    content: '▶'
}

.ss-pause:before,
.ss-pause.right:after {
    content: ''
}

.ss-stop:before,
.ss-stop.right:after {
    content: '■'
}

.ss-record:before,
.ss-record.right:after {
    content: '●'
}

.ss-rewind:before,
.ss-rewind.right:after {
    content: '⏪'
}

.ss-fastforward:before,
.ss-fastforward.right:after {
    content: '⏩'
}

.ss-skipback:before,
.ss-skipback.right:after {
    content: '⏮'
}

.ss-skipforward:before,
.ss-skipforward.right:after {
    content: '⏭'
}

.ss-eject:before,
.ss-eject.right:after {
    content: '⏏'
}

.ss-repeat:before,
.ss-repeat.right:after {
    content: '🔁'
}

.ss-replay:before,
.ss-replay.right:after {
    content: '↺'
}

.ss-shuffle:before,
.ss-shuffle.right:after {
    content: '🔀'
}

.ss-index:before,
.ss-index.right:after {
    content: ''
}

.ss-storagebox:before,
.ss-storagebox.right:after {
    content: ''
}

.ss-book:before,
.ss-book.right:after {
    content: '📕'
}

.ss-notebook:before,
.ss-notebook.right:after {
    content: '📓'
}

.ss-newspaper:before,
.ss-newspaper.right:after {
    content: '📰'
}

.ss-gridlines:before,
.ss-gridlines.right:after {
    content: ''
}

.ss-rows:before,
.ss-rows.right:after {
    content: ''
}

.ss-columns:before,
.ss-columns.right:after {
    content: ''
}

.ss-thumbnails:before,
.ss-thumbnails.right:after {
    content: ''
}

.ss-mouse:before,
.ss-mouse.right:after {
    content: ''
}

.ss-usb:before,
.ss-usb.right:after {
    content: ''
}

.ss-desktop:before,
.ss-desktop.right:after {
    content: '💻'
}

.ss-laptop:before,
.ss-laptop.right:after {
    content: ''
}

.ss-tablet:before,
.ss-tablet.right:after {
    content: ''
}

.ss-smartphone:before,
.ss-smartphone.right:after {
    content: ''
}

.ss-cell:before,
.ss-cell.right:after {
    content: '📱'
}

.ss-battery:before,
.ss-battery.right:after {
    content: '🔋'
}

.ss-highbattery:before,
.ss-highbattery.right:after {
    content: ''
}

.ss-mediumbattery:before,
.ss-mediumbattery.right:after {
    content: ''
}

.ss-lowbattery:before,
.ss-lowbattery.right:after {
    content: ''
}

.ss-chargingbattery:before,
.ss-chargingbattery.right:after {
    content: ''
}

.ss-lightbulb:before,
.ss-lightbulb.right:after {
    content: '💡'
}

.ss-washer:before,
.ss-washer.right:after {
    content: ''
}

.ss-downloadcloud:before,
.ss-downloadcloud.right:after {
    content: ''
}

.ss-download:before,
.ss-download.right:after {
    content: ''
}

.ss-downloadbox:before,
.ss-downloadbox.right:after {
    content: ''
}

.ss-uploadcloud:before,
.ss-uploadcloud.right:after {
    content: ''
}

.ss-upload:before,
.ss-upload.right:after {
    content: ''
}

.ss-uploadbox:before,
.ss-uploadbox.right:after {
    content: ''
}

.ss-fork:before,
.ss-fork.right:after {
    content: ''
}

.ss-merge:before,
.ss-merge.right:after {
    content: ''
}

.ss-refresh:before,
.ss-refresh.right:after {
    content: '↻'
}

.ss-sync:before,
.ss-sync.right:after {
    content: ''
}

.ss-loading:before,
.ss-loading.right:after {
    content: ''
}

.ss-file:before,
.ss-file.right:after {
    content: '📄'
}

.ss-files:before,
.ss-files.right:after {
    content: ''
}

.ss-addfile:before,
.ss-addfile.right:after {
    content: ''
}

.ss-removefile:before,
.ss-removefile.right:after {
    content: ''
}

.ss-checkfile:before,
.ss-checkfile.right:after {
    content: ''
}

.ss-deletefile:before,
.ss-deletefile.right:after {
    content: ''
}

.ss-exe:before,
.ss-exe.right:after {
    content: ''
}

.ss-zip:before,
.ss-zip.right:after {
    content: ''
}

.ss-doc:before,
.ss-doc.right:after {
    content: ''
}

.ss-pdf:before,
.ss-pdf.right:after {
    content: ''
}

.ss-jpg:before,
.ss-jpg.right:after {
    content: ''
}

.ss-png:before,
.ss-png.right:after {
    content: ''
}

.ss-mp3:before,
.ss-mp3.right:after {
    content: ''
}

.ss-rar:before,
.ss-rar.right:after {
    content: ''
}

.ss-gif:before,
.ss-gif.right:after {
    content: ''
}

.ss-folder:before,
.ss-folder.right:after {
    content: '📁'
}

.ss-openfolder:before,
.ss-openfolder.right:after {
    content: '📂'
}

.ss-downloadfolder:before,
.ss-downloadfolder.right:after {
    content: ''
}

.ss-uploadfolder:before,
.ss-uploadfolder.right:after {
    content: ''
}

.ss-quote:before,
.ss-quote.right:after {
    content: '“'
}

.ss-unquote:before,
.ss-unquote.right:after {
    content: '”'
}

.ss-print:before,
.ss-print.right:after {
    content: '⎙'
}

.ss-copier:before,
.ss-copier.right:after {
    content: ''
}

.ss-fax:before,
.ss-fax.right:after {
    content: '📠'
}

.ss-scanner:before,
.ss-scanner.right:after {
    content: ''
}

.ss-printregistration:before,
.ss-printregistration.right:after {
    content: ''
}

.ss-shredder:before,
.ss-shredder.right:after {
    content: ''
}

.ss-expand:before,
.ss-expand.right:after {
    content: '⤢'
}

.ss-contract:before,
.ss-contract.right:after {
    content: ''
}

.ss-help:before,
.ss-help.right:after {
    content: '❓'
}

.ss-info:before,
.ss-info.right:after {
    content: 'ℹ'
}

.ss-alert:before,
.ss-alert.right:after {
    content: '⚠'
}

.ss-caution:before,
.ss-caution.right:after {
    content: '⛔'
}

.ss-logout:before,
.ss-logout.right:after {
    content: ''
}

.ss-login:before,
.ss-login.right:after {
    content: ''
}

.ss-scaleup:before,
.ss-scaleup.right:after {
    content: ''
}

.ss-scaledown:before,
.ss-scaledown.right:after {
    content: ''
}

.ss-plus:before,
.ss-plus.right:after {
    content: '+'
}

.ss-hyphen:before,
.ss-hyphen.right:after {
    content: '-'
}

.ss-check:before,
.ss-check.right:after {
    content: '✓'
}

.ss-delete:before,
.ss-delete.right:after {
    content: '␡'
}

.ss-notifications:before,
.ss-notifications.right:after {
    content: '🔔'
}

.ss-notificationsdisabled:before,
.ss-notificationsdisabled.right:after {
    content: '🔕'
}

.ss-clock:before,
.ss-clock.right:after {
    content: '⏲'
}

.ss-stopwatch:before,
.ss-stopwatch.right:after {
    content: '⏱'
}

.ss-alarmclock:before,
.ss-alarmclock.right:after {
    content: '⏰'
}

.ss-egg:before,
.ss-egg.right:after {
    content: ''
}

.ss-eggs:before,
.ss-eggs.right:after {
    content: ''
}

.ss-cheese:before,
.ss-cheese.right:after {
    content: ''
}

.ss-chickenleg:before,
.ss-chickenleg.right:after {
    content: '🍗'
}

.ss-pizzapie:before,
.ss-pizzapie.right:after {
    content: ''
}

.ss-pizza:before,
.ss-pizza.right:after {
    content: '🍕'
}

.ss-cheesepizza:before,
.ss-cheesepizza.right:after {
    content: ''
}

.ss-frenchfries:before,
.ss-frenchfries.right:after {
    content: '🍟'
}

.ss-apple:before,
.ss-apple.right:after {
    content: '🍏'
}

.ss-carrot:before,
.ss-carrot.right:after {
    content: ''
}

.ss-broccoli:before,
.ss-broccoli.right:after {
    content: ''
}

.ss-cucumber:before,
.ss-cucumber.right:after {
    content: ''
}

.ss-orange:before,
.ss-orange.right:after {
    content: '🍊'
}

.ss-lemon:before,
.ss-lemon.right:after {
    content: '🍋'
}

.ss-onion:before,
.ss-onion.right:after {
    content: ''
}

.ss-bellpepper:before,
.ss-bellpepper.right:after {
    content: ''
}

.ss-peas:before,
.ss-peas.right:after {
    content: ''
}

.ss-grapes:before,
.ss-grapes.right:after {
    content: '🍇'
}

.ss-strawberry:before,
.ss-strawberry.right:after {
    content: '🍓'
}

.ss-bread:before,
.ss-bread.right:after {
    content: '🍞'
}

.ss-mug:before,
.ss-mug.right:after {
    content: '☕'
}

.ss-mugs:before,
.ss-mugs.right:after {
    content: ''
}

.ss-espresso:before,
.ss-espresso.right:after {
    content: ''
}

.ss-macchiato:before,
.ss-macchiato.right:after {
    content: ''
}

.ss-cappucino:before,
.ss-cappucino.right:after {
    content: ''
}

.ss-latte:before,
.ss-latte.right:after {
    content: ''
}

.ss-icedcoffee:before,
.ss-icedcoffee.right:after {
    content: ''
}

.ss-coffeebean:before,
.ss-coffeebean.right:after {
    content: ''
}

.ss-coffeemilk:before,
.ss-coffeemilk.right:after {
    content: ''
}

.ss-coffeefoam:before,
.ss-coffeefoam.right:after {
    content: ''
}

.ss-coffeesugar:before,
.ss-coffeesugar.right:after {
    content: ''
}

.ss-sugarpackets:before,
.ss-sugarpackets.right:after {
    content: ''
}

.ss-capsule:before,
.ss-capsule.right:after {
    content: ''
}

.ss-capsulerecycling:before,
.ss-capsulerecycling.right:after {
    content: ''
}

.ss-insertcapsule:before,
.ss-insertcapsule.right:after {
    content: ''
}

.ss-tea:before,
.ss-tea.right:after {
    content: '🍵'
}

.ss-teabag:before,
.ss-teabag.right:after {
    content: ''
}

.ss-jug:before,
.ss-jug.right:after {
    content: ''
}

.ss-pitcher:before,
.ss-pitcher.right:after {
    content: ''
}

.ss-kettle:before,
.ss-kettle.right:after {
    content: ''
}

.ss-wineglass:before,
.ss-wineglass.right:after {
    content: '🍷'
}

.ss-sugar:before,
.ss-sugar.right:after {
    content: ''
}

.ss-oven:before,
.ss-oven.right:after {
    content: ''
}

.ss-stove:before,
.ss-stove.right:after {
    content: ''
}

.ss-vent:before,
.ss-vent.right:after {
    content: ''
}

.ss-exhaust:before,
.ss-exhaust.right:after {
    content: ''
}

.ss-steam:before,
.ss-steam.right:after {
    content: '♨'
}

.ss-dishwasher:before,
.ss-dishwasher.right:after {
    content: ''
}

.ss-toaster:before,
.ss-toaster.right:after {
    content: ''
}

.ss-microwave:before,
.ss-microwave.right:after {
    content: ''
}

.ss-electrickettle:before,
.ss-electrickettle.right:after {
    content: ''
}

.ss-refrigerator:before,
.ss-refrigerator.right:after {
    content: ''
}

.ss-freezer:before,
.ss-freezer.right:after {
    content: ''
}

.ss-utensils:before,
.ss-utensils.right:after {
    content: '🍴'
}

.ss-cookingutensils:before,
.ss-cookingutensils.right:after {
    content: ''
}

.ss-whisk:before,
.ss-whisk.right:after {
    content: ''
}

.ss-pizzacutter:before,
.ss-pizzacutter.right:after {
    content: ''
}

.ss-measuringcup:before,
.ss-measuringcup.right:after {
    content: ''
}

.ss-colander:before,
.ss-colander.right:after {
    content: ''
}

.ss-eggtimer:before,
.ss-eggtimer.right:after {
    content: ''
}

.ss-platter:before,
.ss-platter.right:after {
    content: ''
}

.ss-plates:before,
.ss-plates.right:after {
    content: ''
}

.ss-steamplate:before,
.ss-steamplate.right:after {
    content: ''
}

.ss-cups:before,
.ss-cups.right:after {
    content: ''
}

.ss-steamglass:before,
.ss-steamglass.right:after {
    content: ''
}

.ss-pot:before,
.ss-pot.right:after {
    content: ''
}

.ss-steampot:before,
.ss-steampot.right:after {
    content: ''
}

.ss-chef:before,
.ss-chef.right:after {
    content: ''
}

.ss-weathervane:before,
.ss-weathervane.right:after {
    content: ''
}

.ss-thermometer:before,
.ss-thermometer.right:after {
    content: ''
}

.ss-thermometerup:before,
.ss-thermometerup.right:after {
    content: ''
}

.ss-thermometerdown:before,
.ss-thermometerdown.right:after {
    content: ''
}

.ss-droplet:before,
.ss-droplet.right:after {
    content: '💧'
}

.ss-sunrise:before,
.ss-sunrise.right:after {
    content: '🌅'
}

.ss-sunset:before,
.ss-sunset.right:after {
    content: '🌇'
}

.ss-sun:before,
.ss-sun.right:after {
    content: '☀'
}

.ss-cloud:before,
.ss-cloud.right:after {
    content: '☁'
}

.ss-clouds:before,
.ss-clouds.right:after {
    content: ''
}

.ss-partlycloudy:before,
.ss-partlycloudy.right:after {
    content: '⛅'
}

.ss-rain:before,
.ss-rain.right:after {
    content: '☔'
}

.ss-rainheavy:before,
.ss-rainheavy.right:after {
    content: ''
}

.ss-lightning:before,
.ss-lightning.right:after {
    content: '☇'
}

.ss-thunderstorm:before,
.ss-thunderstorm.right:after {
    content: '⛈'
}

.ss-umbrella:before,
.ss-umbrella.right:after {
    content: '☂'
}

.ss-rainumbrella:before,
.ss-rainumbrella.right:after {
    content: ''
}

.ss-rainbow:before,
.ss-rainbow.right:after {
    content: '🌈'
}

.ss-rainbowclouds:before,
.ss-rainbowclouds.right:after {
    content: ''
}

.ss-fog:before,
.ss-fog.right:after {
    content: ''
}

.ss-wind:before,
.ss-wind.right:after {
    content: ''
}

.ss-tornado:before,
.ss-tornado.right:after {
    content: ''
}

.ss-snowflake:before,
.ss-snowflake.right:after {
    content: '❄'
}

.ss-snowcrystal:before,
.ss-snowcrystal.right:after {
    content: '❆'
}

.ss-lightsnow:before,
.ss-lightsnow.right:after {
    content: ''
}

.ss-snow:before,
.ss-snow.right:after {
    content: ''
}

.ss-heavysnow:before,
.ss-heavysnow.right:after {
    content: ''
}

.ss-hail:before,
.ss-hail.right:after {
    content: ''
}

.ss-crescentmoon:before,
.ss-crescentmoon.right:after {
    content: '🌙'
}

.ss-waxingcrescentmoon:before,
.ss-waxingcrescentmoon.right:after {
    content: '🌒'
}

.ss-firstquartermoon:before,
.ss-firstquartermoon.right:after {
    content: '🌓'
}

.ss-waxinggibbousmoon:before,
.ss-waxinggibbousmoon.right:after {
    content: '🌔'
}

.ss-waninggibbousmoon:before,
.ss-waninggibbousmoon.right:after {
    content: '🌖'
}

.ss-lastquartermoon:before,
.ss-lastquartermoon.right:after {
    content: '🌗'
}

.ss-waningcrescentmoon:before,
.ss-waningcrescentmoon.right:after {
    content: '🌘'
}

.ss-fan:before,
.ss-fan.right:after {
    content: ''
}

.ss-bike:before,
.ss-bike.right:after {
    content: '🚲'
}

.ss-wheelchair:before,
.ss-wheelchair.right:after {
    content: '♿'
}

.ss-briefcase:before,
.ss-briefcase.right:after {
    content: '💼'
}

.ss-hanger:before,
.ss-hanger.right:after {
    content: ''
}

.ss-comb:before,
.ss-comb.right:after {
    content: ''
}

.ss-medicalcross:before,
.ss-medicalcross.right:after {
    content: ''
}

.ss-up:before,
.ss-up.right:after {
    content: '⬆'
}

.ss-upright:before,
.ss-upright.right:after {
    content: '⬈'
}

.ss-right:before,
.ss-right.right:after {
    content: '➡'
}

.ss-downright:before,
.ss-downright.right:after {
    content: '⬊'
}

.ss-down:before,
.ss-down.right:after {
    content: '⬇'
}

.ss-downleft:before,
.ss-downleft.right:after {
    content: '⬋'
}

.ss-left:before,
.ss-left.right:after {
    content: '⬅'
}

.ss-upleft:before,
.ss-upleft.right:after {
    content: '⬉'
}

.ss-navigateup:before,
.ss-navigateup.right:after {
    content: ''
}

.ss-navigateright:before,
.ss-navigateright.right:after {
    content: '▻'
}

.ss-navigatedown:before,
.ss-navigatedown.right:after {
    content: ''
}

.ss-navigateleft:before,
.ss-navigateleft.right:after {
    content: '◅'
}

.ss-retweet:before,
.ss-retweet.right:after {
    content: ''
}

.ss-share:before,
.ss-share.right:after {
    content: ''
}
