@font-face {
    font-family: 'givsum';
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: 'givsum';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
    content: "\e600";
}

.icon-calendar:before {
    content: "\e601";
}

.icon-ellipsis-circle:before {
    content: "\e602";
}

.icon-ellipsis:before {
    content: "\e603";
}

.icon-expand:before {
    content: "\e604";
}

.icon-gavel:before {
    content: "\e605";
}

.icon-get:before {
    content: "\e606";
}

.icon-give:before {
    content: "\e607";
}

.icon-givsum:before {
    content: "\e608";
}

.icon-heart:before {
    content: "\e609";
}

.icon-lifepreserver:before {
    content: "\e60a";
}

.icon-rows:before {
    content: "\e60b";
}

.icon-settings:before {
    content: "\e60c";
}

.icon-solid-bell:before {
    content: "\e60d";
}

.icon-tickets:before {
    content: "\e60e";
}

#error-page {
    .wrapper {
        padding: 0 !important;
        background-color: #f9f9f9;
        .title {
            margin-top: 20px;
            margin-bottom: -20px;
            h2 {
                font-size: 28px;
                text-align: center;
                color: #ee6840;
                font-weight: bold;
                margin: 0;
            }
        }
    }
}
