@import "./gizmo/ss-gizmo.scss";
@import "./givsum/givsum.scss";

.no_activity {
  padding-top: 50px;
  >.head {
    font-size: 24px;
  }
  >p {
    font-size: 16px;
    margin-block-start: 0em;
  }
}
.vue-app {
    width: 25px;
    height: auto;
    display: block;
    margin: auto;
    position: relative;
  }
.activty_sidebar {
    width: 90vw;
    height: 90vh;
}
.org_sidebar {
  width: 90vw;
  height: 90vh;
}

.activity_button {
  width: 24px !important;
  height: 50px !important;
  text-align: center;
  transform: translateX(0%) translateY(-28.5%);
  &:active {
    background-color: #ee6840;
  }
}
IMG.dummy {
  fill: #AFC6C0 !important;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
  &:hover {
    fill: #157F83 !important;
  }
}
.feed_body {
   font-size: 24px;
   text-align: center;
   margin-top: 60px;
 }

IMG.new_img {
  border: 3px solid #ffffff;
  border-radius: 80% / 65%;
  width: 23px;
  height: 32.2px;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.165);
  position: relative !important;
  z-index: 1 !important;
  object-fit: cover;
  &:hover {
    box-shadow: 0 0 0 5px rgba(0,0,0,0.1);
    color: #5A7877;
  }
}

IMG.new_img_performer {
  width: 30px;
  height: 30px;
  border-radius: 65% / 65%;
  z-index: 0 !important;
  position: absolute !important;
  object-fit: cover;
  &:hover {
    box-shadow: 0 0 0 5px rgba(0,0,0,0.1);
    animation-fill-mode: forwards;
    z-index: 30;
  }
}

@keyframes AVATAR {
  0% {box-shadow: 0 0 0 5px rgba(0,0,0,0.1); transform: translate(0px); z-index: inherit;}
  50% {box-shadow: 0 0 0 5px rgba(0,0,0,0.1); transform: translate(10px); z-index: inherit;}
  100% {box-shadow: 0 0 0 5px rgba(0,0,0,0.1); transform: translate(0px); z-index: inherit;}
}

IMG.new_follower_target {
  border: 3px solid #ffffff;
  border-radius: 80% / 65%;
  width: 23px;
  height: 32.2px;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.165);
  position: relative !important;
  z-index: 0;
  object-fit: cover;
  left: -10px;
  &:hover {
    animation: AVATAR .5s 1;
    animation-fill-mode: forwards;
    z-index: 30;
  }
}

IMG.new_img_target {
  width: 30px;
  height: 30px;
  border-radius: 65% / 65%;
  position: relative !important;
  left: -10px;
  object-fit: cover;
  &:hover {
    animation: AVATAR .5s 1;
    animation-fill-mode: forwards;
  }
}

IMG.new_img_org {
  width: 30px;
  height: 30px;
  border-radius: 65% / 65%;
  object-fit: cover;
  &:hover {
    box-shadow: 0 0 0 5px rgba(0,0,0,0.1);
  }

}

.slide-enter-active,
.slide-leave-active
{
}
.slide-enter,
.slide-leave-to {
}

IMG.loading {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 0px;
  align-items: center;
  width: 25px;
  height: 30px;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  fill: rgba(255, 255, 255, 0);
}
.activity-image {
  align-items: center
}
.activity-feed {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
}
.activity_obj {
  width: 200px;
  margin-left: 60px;
  text-align: left !important;
  line-height: 23.8px !important;
  >.date {
    all: initial;
    display: block;
    color: #b3b3b3;
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;
  }
  >.description {
    all: initial;
    display: block;
    color: #666666;
    font-family: 'Lato';
    font-size: 14px;
    text-align: left !important;
    line-height: 23.8px !important;
     >.msg {
        margin: 0.5rem 0;
        border-left: 3px solid #eee;
        border-radius: 3px;
        padding: 0px 9px;
      }
  }
}

.performer_test {
  display: inline;
}
a.new_a{
  float: initial !important;
  display: initial !important;
  color: #333333 !important;
  font-family: 'Lato' !important;
  font-size: 14px !important;
  line-height: 23.8px !important;
  &:active, &:hover, &:focus {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    color: #ee6840 !important;
  }
}
.feed_object {
  border-bottom: 1px solid #f2f2f2;
  width: 240px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  float: left;
  line-height: 23.8px !important;
}
.feed {
  box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  width: 300px;
  overflow-y: scroll;
}
.feed::-webkit-scrollbar {
  display: none;
}
.avatars {
  width: 61px;
  float: left !important;
  height: auto !important;
  position: relative !important;
}
.performer {
  float: left !important;
}
.target {
  float: left !important;
}
.org_upperbar, .upper_bar {
  width: 300px;
  height: 60px;
  border-bottom: 1px solid #f2f2f2;
  position: fixed;
  background-color: #fff;
  z-index: 5;
  >.users {
    float: left;
    width: 40px;
    height: 50px;
    padding-top: 14px;
    padding-bottom: 5px;
    padding-left: 15px;

    >.ss-users {
      display: flex;
      align-items: center;
      color: rgb(175, 198, 192);
      font-size: 24px !important;
      &:active {
        background-color: #ffffff !important;
      }

    }
  }
  >.heart {
    float: left;
    width: 40px;
    height: 40px;
    padding-top: 13px;
    padding-bottom: 5px;
    padding-left: 5px;
    >.ss-heart {
      display: flex;
      align-items: center;
      color: rgb(175, 198, 192);
      font-size: 24px !important;
      &:active {
        background-color: #ffffff !important;
      }
    }

  }

  >.clock {
    float: left;
    width: 40px;
    height: 40px;
    padding-top: 13px;
    padding-bottom: 5px;
    padding-left: 5px;
    >.ss-clock {
      display: flex;
      align-items: center;
      color: rgb(175, 198, 192);
      font-size: 24px !important;
      &:active {
        background-color: #ffffff !important;
      }
    }
  }

  .get{
    float: left;
    width: 40px;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 5px;
    >.icon-get {
      display: flex;
      align-items: center;
      color: rgb(175, 198, 192);
      font-size: 24px !important;
      &:active {
        background-color: #ffffff !important;
      }
    }

  }
  >.delete {
    float: right;
    width: 40px;
    height: 40px;
    padding-top: 12px;
    padding-bottom: 5px;
    >.ss-delete {
      display: flex;
      align-items: center;
      color: rgb(175, 198, 192) !important;
      font-size: 24px !important;
      >a {
        &:active {
          background-color: #fff;
        }
      }
      &:hover {
        color: #5A7877 !important;
      }
    }

  }
}

.activityIcon {
  width: 25px;
  height: 34.5px;
  z-index: 2010;
  >svg {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    >g {
      >g {
        &:hover {
          fill: #157F83;
        }
      }
    }
  }
}

#f_tooltip {
  width: 80px;
  height: 22px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 8px 7px 10px 7px;
  opacity: 75%;
  font-family: 'Lato';
  font-size: 14px;
  box-sizing: unset;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}


#a_tooltip {
  width: 135px;
  height: 19px;
  top: 50px !important;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 8px 0px 10px 0px;
  opacity: 75%;
  font-family: 'Lato';
  font-size: 14px;
  box-sizing: unset;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 64%;
    margin-left: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}

#o_tooltip {
  width: 135px;
  height: 22px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 8px 0px 10px 0px;
  opacity: 75%;
  font-family: 'Lato';
  font-size: 14px;
  box-sizing: unset;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}

.loading_text {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}

.private_activity{
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  >.ss-lock {
    display: initial;
    align-items: center;
    color: #afc6c0 !important;
    font-size: 36px !important;
    left: 47%;
    width: 36px;
    margin: 0px;
  }
  h4 {
    margin: 0;
  }
}

